export default function pagination() {
    const nextPage = document.querySelector('#next-offers');
    const listWrapper = document.querySelector('#js-lista');

    if (nextPage) {
        nextPage.addEventListener('click', function () {
            const xhr = new XMLHttpRequest();

            xhr.open('POST', '/nlt/p/' + nextPage.dataset.page + '?async=1', true);

            xhr.onreadystatechange = () => {

                if (xhr.status === 200) {
                    if (xhr.readyState === 4) {
                        const response = xhr.responseText;
                        let currentScrollPosition = window.pageYOffset;

                        window.history.pushState(nextPage.dataset.page, '', nextPage.dataset.page);

                        listWrapper.innerHTML += response;
                        nextPage.dataset.page++;
                        window.scrollTo(0, currentScrollPosition);
                    }

                }
            }
            xhr.send();
        })
    }
}