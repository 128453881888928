export default class SideModal {
    constructor() {

    }

    init() {

    }



}