export default function search(valueToSearch) {

    const brandsFoundedBox = document.querySelector('#brands-found');
    const carsFoundedBox = document.querySelector('#cars-found');
    const viewAllCars = document.querySelector('#view-all-cars');

    const xhr = new XMLHttpRequest();

    xhr.open('GET', '/search?search=' + valueToSearch, true);

    xhr.onreadystatechange = function () {

        if (xhr.readyState == 4 && xhr.status == 200) {

            const response = xhr.responseText;
            const responseObject = JSON.parse(response);

            if (responseObject.brands) {

                const brands = responseObject.brands;
                brandsFoundedBox.textContent = '';
                brands.forEach(brand => {
                    const brandElement = document.createElement('a');
                    const imageBrand = document.createElement('img');

                    brandElement.setAttribute('href', window.location.origin + '/offerte-noleggio-lungo-termine-' + brand.slug)
                    brandElement.setAttribute('class', 'inline-flex mr-2 border border-white rounded items-center hover:opacity-75')
                    imageBrand.setAttribute('src', 'https://www.facilerent.it/public/brands56/' + brand.slug + '.png')

                    brandElement.append(imageBrand);
                    brandsFoundedBox.append(brandElement);

                });
            }

            if (responseObject.data) {

                const cars = responseObject.data;

                carsFoundedBox.textContent = '';
                for (let index = 0; index < 3; index++) {
                    if((typeof cars[index] !== 'undefined')){
                       // console.log(cars[index]);
                        const carElement = document.createElement('a');
                        const imageCarElementSpan = document.createElement('span');
                        const imageCarElement = document.createElement('img');
                        const carHeadingElement = document.createElement('span');
                        const carModelElement = document.createElement('span');
                        const carVersionElement = document.createElement('span');
    
                        carElement.setAttribute('href', cars[index].offerta) 
                        carElement.setAttribute('class', 'flex items-center rounded  py-2 border border-white hover:bg-gray-100')
    
                        imageCarElementSpan.setAttribute('class', 'flex items-center');
                        imageCarElement.setAttribute('class', 'w-24');
                        imageCarElement.setAttribute('src', cars[index].image);
                        imageCarElementSpan.append(imageCarElement);
    
                        carHeadingElement.setAttribute('class', 'ml-4')
    
                        carModelElement.setAttribute('class', 'block text-md font-semibold');
                        carModelElement.textContent = cars[index].marca + ' ' + cars[index].modello + ' ' + cars[index].allestimento;
    
                        carVersionElement.setAttribute('class', 'block text-gray-600 text-xs');
                        carVersionElement.textContent = 'anticipo:' + cars[index].anticipo + ' | ' + cars[index].durata + ' mesi | ' + cars[index].km + ' km | €' + cars[index].prezzo;
    
                        carHeadingElement.append(carModelElement);
                        carHeadingElement.append(carVersionElement);
    
                        carElement.append(imageCarElementSpan);
                        carElement.append(carHeadingElement);
                        carsFoundedBox.append(carElement);
                  }
                }

                viewAllCars.classList.remove('hidden')
                viewAllCars.setAttribute('href', '/search/s?search=' + valueToSearch)

            }

            if (responseObject.suggerimenti === 1) {


                const suggestionBlock = document.querySelector('#suggestion-block');
                const suggestionFound = document.querySelector('#suggestion-found');
                const brandsBlock = document.querySelector('#brands-block');
                const carsBlock = document.querySelector('#cars-block');
                suggestionBlock.classList.remove('hidden')
                brandsBlock.classList.add('hidden')
                carsBlock.classList.add('hidden')

                const results = responseObject.valore;
                console.log(results);
                suggestionFound.textContent = '';
                results.forEach(result => {
                    console.log(result);

                    const resultElement = document.createElement('a');


                    resultElement.setAttribute('href', result.risposta)
                    resultElement.setAttribute('class', 'block mr-2 border border-white rounded items-center hover:opacity-75')
                    resultElement.textContent = result.valore;
                    suggestionFound.append(resultElement);

                });

                // const brands = responseObject.valore;

                // brands.forEach(brand => {

                //     const brandElement = document.createElement('a');
                //     const imageBrand = document.createElement('img');
                //     suggestionFound.textContent = '';
                //     brandElement.setAttribute('href', window.location.origin + '/offerte-noleggio-lungo-termine-' + brand.valore.toLowerCase())
                //     brandElement.setAttribute('class', 'inline-flex mr-2 border border-white rounded items-center hover:opacity-75')
                //     imageBrand.setAttribute('src', 'https://www.facilerent.it/public/brands56/' + brand.valore.toLowerCase() + '.png')

                //     brandElement.append(imageBrand);
                //     suggestionFound.append(brandElement);

                // });
                viewAllCars.classList.add('hidden')

            } else if (responseObject.suggerimenti === 0) {

                const suggestionBlock = document.querySelector('#suggestion-block');
                const suggestionFound = document.querySelector('#suggestion-found');
                const brandsBlock = document.querySelector('#brands-block');
                const carsBlock = document.querySelector('#cars-block');
                suggestionBlock.classList.remove('hidden')
                brandsBlock.classList.add('hidden')
                carsBlock.classList.add('hidden')
                suggestionFound.textContent = 'Nessun suggerimento trovato';

            } else {

                const suggestionBlock = document.querySelector('#suggestion-block');
                const suggestionFound = document.querySelector('#suggestion-found');
                const brandsBlock = document.querySelector('#brands-block');
                const carsBlock = document.querySelector('#cars-block');
                suggestionBlock.classList.add('hidden')
                brandsBlock.classList.remove('hidden')
                carsBlock.classList.remove('hidden')
                suggestionFound.textContent = '';

            }

        }
    }

    xhr.send();

}