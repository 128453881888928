export default function cartAjax() {
    const favoritesBox = document.querySelector('#favorites-box');
    const openCart = document.querySelector('#open-cart');

    if (openCart) {

        openCart.addEventListener('click', function () {

            if (!favoritesBox.classList.contains('hidden')) {
                const xhr = new XMLHttpRequest();

                xhr.open('GET', '/carrello/cart_ajax', true);

                xhr.onreadystatechange = function () {

                    if (xhr.readyState == 4 && xhr.status == 200) {

                        const response = xhr.responseText;
                        favoritesBox.innerHTML = response;
                        favoritesBox.classList.remove('hidden');

                        const closeCart = document.querySelector('#close-cart');

                        if (closeCart) {
                            closeCart.addEventListener('click', function () {
                                favoritesBox.classList.add('hidden');
                            })
                        }
                        removeElementFromCart();

                    }

                }

                xhr.send();
            } else {
                favoritesBox.classList.add('hidden');
            }

        })

    }

    function removeElementFromCart() {

        const removeFromCart = document.querySelectorAll('.remove-from-cart');
        if (removeFromCart) {
            removeFromCart.forEach(remove => {
                remove.addEventListener('click', function () {

                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', '/carrello/quote_remove/' + this.dataset.key + '?redirect=0', true);
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState == 4 && xhr.status == 200) {
                            const response = xhr.responseText;
                            favoritesBox.innerHTML = response;
                            removeElementFromCart();

                            const closeCart = document.querySelector('#close-cart');
                            if (closeCart) {
                                closeCart.addEventListener('click', function () {
                                    favoritesBox.classList.add('hidden');
                                })
                            }
                        }
                    }

                    xhr.send();
                    if (closeCart) {
                        closeCart.addEventListener('click', function () {
                            favoritesBox.classList.add('hidden');
                        })
                    }

                })
            });

        }
    }

}