export default function userMenu() {
    const avatar = document.querySelector('#avatar');
    const closeUserProfileMenu = document.querySelector('#close-user-profile-menu');
    const userProfileMenu = document.querySelector('#user-profile-menu');

    avatar.addEventListener('click', function () {
        userProfileMenu.classList.toggle('hidden');
        closeUserProfileMenu.classList.toggle('hidden');
        document.body.classList.toggle('overflow-hidden');
    })

    closeUserProfileMenu.addEventListener('click', function () {

        userProfileMenu.classList.add('hidden');
        this.classList.add('hidden')
        document.body.classList.remove('overflow-hidden');
    })
}
