import search from "./search";
export default function searchBar() {
    const searchBarContainer = document.querySelector('#search-bar-container');
    const searchButton = document.querySelector('#search-button');
    const searchBar = document.querySelector('#search-bar');
    const searchWindow = document.querySelector('#search-window');
    const viewAllCars = document.querySelector('#view-all-cars');

    searchButton.addEventListener('click', function () {
        if (searchBarContainer.classList.contains('h-0')) {
            searchBarContainer.classList.remove('h-0');
            searchBarContainer.classList.add('h-full');
            searchBar.focus();
            //check if touch outside search window
            searchBarContainer.addEventListener('click', function (e) {
                if (e.target !== searchWindow && e.target !== searchButton && e.target !== searchBar && e.target !== viewAllCars) {
                    searchBarContainer.classList.add('h-0');
                }
            });
           
        } else if (searchBarContainer.classList.contains('h-full')) {
            searchBarContainer.classList.add('h-0');
        }
        });


       
         

    searchBar.addEventListener('keyup', function () {


        if (searchBar.value.length > 2) {
            searchWindow.classList.remove('hidden');
            search(searchBar.value);
        } else {
            searchWindow.classList.add('hidden');
            viewAllCars.classList.add('hidden')
            createBrandsSkeleton();
            createCarsSkeleton();
        }

    });
}

function createBrandsSkeleton() {
    const brandsFoundedBox = document.querySelector('#brands-found');
    brandsFoundedBox.textContent = '';

    const skeletonContainer = document.createElement('div');

    for (let index = 0; index < 3; index++) {
        const skeletonSpanOuter = document.createElement('span');
        const skeletonSpanInner = document.createElement('span');
        skeletonContainer.setAttribute('class', 'flex flex-wrap w-full');
        skeletonSpanOuter.setAttribute('class', 'skeleton-loader block relative mr-3 w-8 h-8 bg-gray-200 rounded-full overflow-hidden');
        skeletonSpanInner.setAttribute('class', 'skeleton-loader-gradient block absolute top-0 w-1/2 h-full bg-gradient-r-skeleton');
        skeletonSpanOuter.append(skeletonSpanInner);
        skeletonContainer.append(skeletonSpanOuter);
    }

    brandsFoundedBox.append(skeletonContainer);
}

function createCarsSkeleton() {
    const carsFoundedBox = document.querySelector('#cars-found');
    carsFoundedBox.textContent = '';

    const car = document.createElement('div');
    car.setAttribute('class', 'flex items-center');

    const carImageDiv = document.createElement('div');
    const carImageOuterSpan = document.createElement('span');
    const carImageInnerSpan = document.createElement('span');

    carImageOuterSpan.setAttribute('class', 'skeleton-loader block relative mr-3 w-20 h-20 bg-gray-200 rounded overflow-hidden');
    carImageInnerSpan.setAttribute('class', 'skeleton-loader-gradient block absolute top-0 w-1/2 h-full bg-gradient-r-skeleton');

    carImageOuterSpan.append(carImageInnerSpan);
    carImageDiv.append(carImageOuterSpan);

    const carHeadingDiv = document.createElement('div');

    const carTitleOuterSpan = document.createElement('span');
    const carTitleInnerSpan = document.createElement('span');

    carTitleOuterSpan.setAttribute('class', 'skeleton-loader block relative mb-3 w-56  h-5 bg-gray-400 rounded overflow-hidden');
    carTitleInnerSpan.setAttribute('class', 'skeleton-loader-gradient block absolute top-0 w-1/2 h-full bg-gradient-r-skeleton');

    const carSubTitleOuterSpan = document.createElement('span');
    const carSubTitleInnerSpan = document.createElement('span');

    carSubTitleOuterSpan.setAttribute('class', 'skeleton-loader block relative mr-3 w-40 h-3 bg-gray-200 rounded overflow-hidden');
    carSubTitleInnerSpan.setAttribute('class', 'skeleton-loader-gradient block absolute top-0 w-1/2 h-full bg-gradient-r-skeleton');

    carImageOuterSpan.append(carImageInnerSpan);
    carImageDiv.append(carImageOuterSpan);

    carTitleOuterSpan.append(carTitleInnerSpan);
    carSubTitleOuterSpan.append(carSubTitleInnerSpan);
    carHeadingDiv.append(carTitleOuterSpan);
    carHeadingDiv.append(carSubTitleOuterSpan);


    car.append(carImageDiv);
    car.append(carHeadingDiv);
    carsFoundedBox.append(car);


}