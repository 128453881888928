export default function select() {
    document.querySelectorAll('.select-field').forEach((selectField) => {
        selectField.querySelector('.option-text').innerHTML = selectField.querySelector('.selected').innerHTML;
        selectField.addEventListener('click', () => {
        selectField.querySelector('.select-options').classList.toggle('hidden');
        selectField.querySelector('.arrow').classList.toggle('rotate-180');
        });
        selectField.querySelectorAll('.option').forEach((selectOption) => {
        selectOption.addEventListener('click', () => {
        selectField.querySelector('.selected').classList.remove('selected');
        selectOption.classList.add('selected');
        selectField.querySelector('.option-text').innerHTML = selectOption.innerHTML;
        });
   });
});

}